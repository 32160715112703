$(document).on('change', '#purchase_order_budget_category_id', function (e) {
  var budgetCategoryId = $(this).find(':selected').val();

  $.ajax({
    method: 'GET',
    url: `/budget_categories/${budgetCategoryId}/remaining_budget`,
    dataType: 'json',
    success: function (data, status, xhr) {
      if (data["success"] === true && data["remaining_budget"] !== "not available") {
        $('#remaining_budget_span').text(`Remaining Budget: ${data["remaining_budget"]}`);
      } else {
        $('#remaining_budget_span').text('');
      }
    }
  });
});

$(document).on('change', '#purchase_order_company_id', function (e) {
  var companyId = $(this).find(':selected').val();

  var options = '<option value>Loading...</option>';
  $('#purchase_order_office_id').selectize()[0].selectize.destroy();
  $('#purchase_order_office_id').html(options);
  $('#purchase_order_office_id').selectize();

  $.ajax({
    method: 'GET',
    url: `/companies/${companyId}/offices`,
    dataType: 'json',
    success: function (data, status, xhr) {
      options = '<option value>Select Office</option>';

      $.each(data['offices'], function (index, office) {
        options += `<option value='${office.id}'>${office.name}</option>`;
      });

      $('#purchase_order_office_id').prop('required', true);
      $('#purchase_order_office_id').selectize()[0].selectize.destroy();
      $('#purchase_order_office_id').html(options);
      $('#purchase_order_office_id').selectize();
    }
  });

  setContractedRatesForAllItems();
});

$(document).on('change', '#purchase_order_vendor_id', function (e) {
  setContractedRatesForAllItems();
});

$(document).on('change', '.purchase-order-item-selection', function (e) {
  e.preventDefault();

  var itemId = $(this).find(':selected').val();
  var uniqueId = $(this).attr('id')?.split('_')[6];

  if (!itemId) return;

  const select_class = `#purchase_order_purchase_order_items_attributes_${uniqueId}`;
  let select_catalogue = `${select_class}_item_catalogue_id`;
  let select_unit = `${select_class}_uom_id`;

  // var selectizeParams1 = {
  //   create: function(input) {
  //     return {
  //       value: input,
  //       text: input
  //     }
  //   },
  //   delimiter: ',',
  //   persist: false
  // }

  var optionsBorC = '<option value>Loading...</option>';
  $(`${select_catalogue}`).selectize()[0].selectize.destroy();
  $(`${select_catalogue}`).html(optionsBorC);
  $(`${select_catalogue}`).selectize();

  // var selectizeParams2 = {
  //   create: function(input) {
  //     return {
  //       value: input,
  //       text: input
  //     }
  //   },
  //   delimiter: ',',
  //   persist: false,
  //   plugins: ['inputMaxlength'],
  //   inputMaxlength: 4
  // }

  var optionsUOM = '<option value>Loading...</option>';
  $(`${select_unit}`).selectize()[0].selectize.destroy();
  $(`${select_unit}`).html(optionsUOM);
  $(`${select_unit}`).selectize();

  $.ajax({
    method: 'GET',
    url: `/items/${itemId}`,
    dataType: 'json',
    success: function (data, status, xhr) {
      // handle rate
      let { buying_price, buying_currency, uom_id } = data.item;
      const currency = $('#po-currency').selectize()[0].selectize.getValue()

      if (currency == buying_currency) {
        $(`${select_class}_rate`).val(buying_price)
        $(`${select_class}_rate`).parent().find('#other-currency').text('')
      } else if (buying_price) {
        $(`${select_class}_rate`).val('')
        $(`${select_class}_rate`).parent().find('#other-currency').text(`${buying_currency} ${buying_price}`)
      } else {
        $(`${select_class}_rate`).val('')
        $(`${select_class}_rate`).parent().find('#other-currency').text('')
      }

      // handle item catalogues
      optionsBorC = '<option value>Brand or Catalogue</option>';
      $.each(data.item['item_catalogues'], function (index, item_catalogue) {
        optionsBorC += `<option value='${item_catalogue.id}'>${item_catalogue.brand_with_cat_no}</option>`;
      });

      $(`${select_catalogue}`).selectize()[0].selectize.destroy();
      $(`${select_catalogue}`).html(optionsBorC);
      $(`${select_catalogue}`).selectize();

      // handle unit
      optionsUOM = '<option value>Unit</option>';

      $.each(data.item['uoms'], function (index, uom) {
        optionsUOM += `<option value='${uom.id}'>${uom.short_name}</option>`;
      });

      $(`${select_unit}`).selectize()[0].selectize.destroy();
      $(`${select_unit}`).html(optionsUOM);
      $(`${select_unit}`).val(uom_id);
      $(`${select_unit}`).selectize();
    },
    error: function () {
      // handle item catalogues
      optionsBorC = '<option value>Failed!</option>';

      $(`${select_catalogue}`).selectize()[0].selectize.destroy();
      $(`${select_catalogue}`).html(optionsBorC);
      $(`${select_catalogue}`).selectize();

      // handle unit
      optionsUOM = '<option value>Failed!</option>';
      let unit = "";

      $(`${select_unit}`).selectize()[0].selectize.destroy();
      $(`${select_unit}`).html(optionsUOM);
      $(`${select_unit}`).val(unit);
      $(`${select_unit}`).selectize();
    }
  });

  var itemCatalogueId = $(`#purchase_order_purchase_order_items_attributes_${uniqueId}_item_catalogue_id`).val();
  var vendorId = $('#purchase_order_vendor_id').val();
  var companyId = $('#purchase_order_company_id').val();

  if ($.isNumeric(itemId) && $.isNumeric(vendorId) && $.isNumeric(companyId)) {
    setRateForRateContractedItem(itemId, itemCatalogueId, vendorId, companyId, uniqueId);
  }
});

$(document).on('change', '.purchase-order-item-catalogue-selection', function (e) {
  e.preventDefault();
  var itemCatalogueId = $(this).find(':selected').val();
  var uniqueId = $(this).attr('id')?.split('_')[6];
  var itemId = $(`#purchase_order_purchase_order_items_attributes_${uniqueId}_item_id`).val();
  var vendorId = $('#purchase_order_vendor_id').val();
  var companyId = $('#purchase_order_company_id').val();

  if ($.isNumeric(itemId) && $.isNumeric(vendorId) && $.isNumeric(companyId)) {
    setRateForRateContractedItem(itemId, itemCatalogueId, vendorId, companyId, uniqueId);
  }
});

function setContractedRatesForAllItems() {
  var vendorId = $('#purchase_order_vendor_id').val();
  var companyId = $('#purchase_order_company_id').val();

  $('.purchase-order-item-selection').each(function (e) {
    var itemId = $(this).val();

    if (itemId == undefined || itemId == '') {
      return
    }

    var uniqueId = $(this).attr('id')?.split('_')[6];
    var itemCatalogueId = $(`#purchase_order_purchase_order_items_attributes_${uniqueId}_item_catalogue_id`).val();

    if ($.isNumeric(itemId) && $.isNumeric(vendorId) && $.isNumeric(companyId)) {
      setRateForRateContractedItem(itemId, itemCatalogueId, vendorId, companyId, uniqueId);
    }
  });
}

function setRateForRateContractedItem(itemId, itemCatalogueId, vendorId, companyId, uniqueId) {
  $.ajax({
    method: 'GET',
    url: `/items/${itemId}/contracted_rate?item_catalogue_id=${itemCatalogueId}&vendor_id=${vendorId}&currency=${window.localStorage.getItem('currency')}&company_id=${companyId}`,
    dataType: 'json',
    success: function (data, status, xhr) {
      var rate = data['rate'];

      if ($.isNumeric(rate)) {
        $(`#purchase_order_purchase_order_items_attributes_${uniqueId}_rate`).val(rate);
      }
    }
  });
}
