const { Loading, throttle } = require("./utility");

$(document).on('turbolinks:load', function () {
  $(document).on('change', '.quick-item-selection, .quick-uom-selection, .quick-item-catalogue-selection, .quick-cost-center-selection', function() {
    // $(this)? is required as selectize mutates dom which results in not behaving as intended
    $(this)?.closest('.quick-group').find('.quick-status').addClass('hide').removeClass('fa-check fa-xmark').css("#e66100");
  })

  $(document).on('blur', '.quick-item-selection, .quick-uom-selection, .quick-item-catalogue-selection, .quick-cost-center-selection', function() {
    const el = $(this).closest('.quick-items');
    var val1 = el.find('.quick-item-selection').find(':selected').val();
    var val2 = el.find('.quick-uom-selection').find(':selected').val();
    var val3 = el.find('.quick-cost-center-selection').find(':selected').val();
    var val4 = el.find('.quick-item-catalogue-selection').find(':selected').val();

    if ([val1, val2, val3, val4].every(el => el == ""  || Number.isInteger(parseInt(el)))) {
      FormSubmitButton.enable(el);
    }
  })

  $(document).on('change', '.quick-item-selection', function() {
    const el = $(this).closest('.quick-items');
    var val = el.find('.quick-item-selection').find(':selected').val();

    if (val !== undefined && val.length > 0 && !Number.isInteger(parseInt(val))) {
      Loading.enable("item")
      FormSubmitButton.disable(el);
    }
  })

  $(document).on('change', '.quick-uom-selection', function() {
    const el = $(this).closest('.quick-items');
    var val = el.find('.quick-uom-selection').find(':selected').val();

    if (val !== "" && !Number.isInteger(parseInt(val))) {
      Loading.enable("uom")
      FormSubmitButton.disable(el);
    }
  })

  $(document).on('change', '.quick-item-catalogue-selection', function() {
    const el = $(this).closest('.quick-items');
    var val = el.find('.quick-item-catalogue-selection').find(':selected').val();

    if (val !== "" && !Number.isInteger(parseInt(val))) {
      Loading.enable("item_catalogue")
      FormSubmitButton.disable(el);
    }
  })

  $(document).on('change', '.quick-cost-center-selection', function() {
    const el = $(this).closest('.quick-items');
    var val = el.find('.quick-cost-center-selection').find(':selected').val();

    if (val !== "" && !Number.isInteger(parseInt(val))) {
      Loading.enable("cost_center")
      FormSubmitButton.disable(el);
    }
  })

  $(document).on('click', '.validate-btn', create_item_uom)

  $(document).on('click', '.validate-btn', create_cost_center)

  $(document).on('click', '.validate-btn', create_independent_catalogue)

  function create_item_catalogue(itemId, brandName, el) {
    const validItem = !!itemId && Number.isInteger(parseInt(itemId))
    const validBrand = !!brandName && !Number.isInteger(parseInt(brandName))

    if (!validItem || !validBrand) {
      // console.log("Not required for item or brand");
      return;
    }

    el.find('.quick-item-catalogue-selection').closest('.quick-group').find('.quick-status').removeClass('hide fa-check fa-xmark').addClass('fa-spinner spin');

    $.ajax({
      method: 'POST',
      url: `/master/item_catalogues/`,
      dataType: 'json',
      data: {
        item_id: itemId,
        brand_name: brandName,
        mode: 'quick'
      },
      success: function (data, status, xhr) {
        el.find('.quick-item-catalogue-selection').find(':selected').val(data.id)
        el.find('.quick-item-catalogue-selection').closest('.quick-group').find('.quick-status').removeClass('fa-spinner spin').addClass('fa-check').css('color', "#2ec27e");

        Loading.disable("item_catalogue");
      },
      error: function () {
        el.find('.quick-item-catalogue-selection').closest('.quick-group').find('.quick-status').removeClass('fa-spinner spin').addClass('fa-xmark').css('color', "#c01c28");
      },
      complete: function() {
        if (Loading.isAllDisabled()) {
          FormSubmitButton.enable(el);
        }
      }
    });
  }

  function create_item_uom() {
    const el = $(this).closest('.quick-items');

    var itemName = el.find('.quick-item-selection').find(':selected').val();
    var uomName = el.find('.quick-uom-selection').find(':selected').val();

    const validItem = !!itemName && !Number.isInteger(parseInt(itemName))
    const validUom = !!uomName

    if (!validItem || !validUom) {
      // console.log("Not required for item or uom");
      return;
    }

    el.find('.quick-item-selection').closest('.quick-group').find('.quick-status').removeClass('hide fa-check fa-xmark').addClass('fa-spinner spin');
    el.find('.quick-uom-selection').closest('.quick-group').find('.quick-status').removeClass('hide fa-check fa-xmark').addClass('fa-spinner spin');


    $.ajax({
      method: 'POST',
      url: `/master/items/`,
      dataType: 'json',
      data: {
        item_name: itemName,
        item_group_name: itemName,
        uom_name: uomName,
        mode: 'quick'
      },
      success: function (data, status, xhr) {
        el.find('.quick-item-selection').find(':selected').val(data.id)
        el.find('.quick-uom-selection').find(':selected').val(data.uom_id)

        el.find('.quick-item-selection').closest('.quick-group').find('.quick-status').removeClass('fa-spinner spin').addClass('fa-check').css('color', "#2ec27e");
        el.find('.quick-uom-selection').closest('.quick-group').find('.quick-status').removeClass('fa-spinner spin').addClass('fa-check').css('color', "#2ec27e");

        Loading.disable("item");
        Loading.disable("uom");

        var brandName = el.find('.quick-item-catalogue-selection').find(':selected').val();
        create_item_catalogue(data.id, brandName, el);
      },
      error: function () {
        el.find('.quick-item-selection').closest('.quick-group').find('.quick-status').removeClass('fa-spinner spin').addClass('fa-xmark').css('color', "#c01c28");
        el.find('.quick-uom-selection').closest('.quick-group').find('.quick-status').removeClass('fa-spinner spin').addClass('fa-xmark').css('color', "#c01c28");
      },
      complete: function() {
        if (Loading.isAllDisabled()) {
          FormSubmitButton.enable(el);
        }
      }
    });
  }

  function create_cost_center() {
    const el = $(this).closest('.quick-items');

    var costCenterName = el.find('.quick-cost-center-selection').find(':selected').val();

    const validCostCenter = !!costCenterName && !Number.isInteger(parseInt(costCenterName))

    if (!validCostCenter) {
      // console.log("Not required for cost center");
      return;
    }

    el.find('.quick-cost-center-selection').closest('.quick-group').find('.quick-status').removeClass('hide fa-check fa-xmark').addClass('fa-spinner spin');

    $.ajax({
      method: 'POST',
      url: `/master/cost_centers/`,
      dataType: 'json',
      data: {
        cost_center: { name: costCenterName },
        mode: 'quick'
      },
      success: function (data, status, xhr) {
        el.find('.quick-cost-center-selection').find(':selected').val(data.id)
        el.find('.quick-cost-center-selection').closest('.quick-group').find('.quick-status').removeClass('fa-spinner spin').addClass('fa-check').css('color', "#2ec27e");

        Loading.disable("cost_center")
      },
      error: function () {
        el.find('.quick-cost-center-selection').closest('.quick-group').find('.quick-status').removeClass('fa-spinner spin').addClass('fa-xmark').css('color', "#c01c28");
      },
      complete: function() {
        if (Loading.isAllDisabled()) {
          FormSubmitButton.enable(el);
        }
      }
    });
  }

  function create_independent_catalogue() {
    const el = $(this).closest('.quick-items');

    var itemId = el.find('.quick-item-selection').find(':selected').val();
    var brandName = el.find('.quick-item-catalogue-selection').find(':selected').val();

    create_item_catalogue(itemId, brandName, el);
  }

  var FormSubmitButton = (() => {
    const fbtn = $('.form-btn');
    const vbtn = $('.validate-btn');
    
    var enable = (el) => {
      fbtn.removeClass('hide');
      var elem = el && el.length > 0 ? el.find('.validate-btn') : vbtn
      elem.addClass('hide').removeAttr('disabled');
    }
    
    var disable = (el) => {
      fbtn.addClass('hide');
      var elem = el && el.length > 0 ? el.find('.validate-btn') : vbtn
      elem.removeClass('hide').removeAttr('disabled');
    }

    return {enable, disable}
  })();

  $(document).on('click', '.validate-btn', function(e) {
    e.preventDefault();
    $(this).attr('disabled', 'disabled');
  })
})
