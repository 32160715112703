if (window.hasOwnProperty("Selectize") ) {
  Selectize.define('inputMaxlength', function(options) {
    var self = this;
    this.setup = (function() {
      var original = self.setup;
      return function() {
        original.apply(this, arguments);
        this.$control_input.attr('maxlength', this.settings.inputMaxlength);
      };
    })();
  });
  
  Selectize.define('preventKeys', function(options) {
    var self = this;
    this.setup = (function() {
      var original = self.setup;
      return function() {
        original.apply(this, arguments);
        this.$control_input.on('keydown', function(e) {
          var key = e.originalEvent.key;
          if (self.settings.preventKeys.includes(key)) {  // NOTE: this.settings not working here
            // console.log("This key not allowed");
            e.preventDefault();
          } else {
            return true;
          }
        });
  
        function sanitize(s) {
          // NOTE: this.settings not working here
          var settingKeys = self.settings.preventKeys;
          var unwantedChars = settingKeys.join("");
  
          var pattern = new RegExp(unwantedChars, "g");
          return s.replace(pattern, "")
        };  
  
        this.$control_input.on('paste', function(e) {
          try {
            var clipboardData = e.originalEvent.clipboardData;
            var text = clipboardData.getData('text');
            $(this).val(sanitize(text));
            e.preventDefault();
          } catch (e) {
            console.error("Clipboard does not work on this browser");
          }        
        });
      };
    })();
  });  
}
