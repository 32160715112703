$(document).on('turbolinks:load', function() {
  $('.purchase-request-selection').change(function() {
    var clickedId = $(this).val();

    $.ajax({
      method: 'PUT',
      url: '/procurement/quotation_requests/store_purchase_request_item',
      dataType: 'json',
      data: { purchase_request_item_id: clickedId }
    });
  });

  $('.vendorship-selection').change(function() {
    var clickedId = $(this).val();

    $.ajax({
      method: 'PUT',
      url: '/procurement/quotation_requests/store_vendor',
      dataType: 'json',
      data: { vendorship_id: clickedId }
    });
  });
});
