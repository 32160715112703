$(document).on('change', '.stock-request-item-selection', function (e) {
  e.preventDefault();

  var itemId = $(this).find(':selected').val();
  var uniqueId = $(this).attr('id').split('_')[6];

  if (!itemId) return;

  const select_class = `#stock_request_stock_request_items_attributes_${uniqueId}`;
  let select_catalogue = `${select_class}_item_catalogue_id`;
  let select_unit = `${select_class}_uom_id`;

  var optionsBorC = '<option value>Loading...</option>';
  $(`${select_catalogue}`).selectize()[0].selectize.destroy();
  $(`${select_catalogue}`).html(optionsBorC);
  $(`${select_catalogue}`).selectize();

  var optionsUOM = '<option value>Loading...</option>';
  $(`${select_unit}`).selectize()[0].selectize.destroy();
  $(`${select_unit}`).html(optionsUOM);
  $(`${select_unit}`).selectize();

  $.ajax({
    method: 'GET',
    url: `/items/${itemId}`,
    dataType: 'json',
    success: function (data, status, xhr) {
      // handle rate
      let { buying_price, buying_currency, uom_id } = data.item;

      // $(`${select_class}_rate`).val(buying_price)

      // handle item catalogues
      optionsBorC = '<option value>Brand or Catalogue</option>';
      $.each(data.item['item_catalogues'], function (index, item_catalogue) {
        optionsBorC += `<option value='${item_catalogue.id}'>${item_catalogue.brand_with_cat_no}</option>`;
      });

      $(`${select_catalogue}`).selectize()[0].selectize.destroy();
      $(`${select_catalogue}`).html(optionsBorC);
      $(`${select_catalogue}`).selectize();

      // handle unit
      optionsUOM = '<option value>Unit</option>';

      $.each(data.item['uoms'], function (index, uom) {
        optionsUOM += `<option value='${uom.id}'>${uom.short_name}</option>`;
      });

      $(`${select_unit}`).selectize()[0].selectize.destroy();
      $(`${select_unit}`).html(optionsUOM);
      $(`${select_unit}`).val(uom_id);
      $(`${select_unit}`).selectize();
    },
    error: function () {
      // handle item catalogues
      optionsBorC = '<option value>Failed!</option>';

      $(`${select_catalogue}`).selectize()[0].selectize.destroy();
      $(`${select_catalogue}`).html(optionsBorC);
      $(`${select_catalogue}`).selectize();

      // handle unit
      optionsUOM = '<option value>Failed!</option>';
      let unit = "";

      $(`${select_unit}`).selectize()[0].selectize.destroy();
      $(`${select_unit}`).html(optionsUOM);
      $(`${select_unit}`).val(unit);
      $(`${select_unit}`).selectize();
    }
  });
});
