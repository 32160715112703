$(document).on('turbolinks:load', function() {
  $('#all_check').click(function(e) {
    var checked = $(this).prop('checked');
    $('.bulk-action-checkbox').prop('checked', checked);
    bulkActionDisplayToggle();
  })

  $('.bulk-action-checkbox').click(function(e) {
    bulkActionDisplayToggle()
    var checkboxCount = $('.bulk-action-checkbox').length;
    var checkedCount = $('.bulk-action-checkbox:checked').length;

    if (checkboxCount === checkedCount) {
      $('#all_check').prop('checked', true).prop('indeterminate', false);
    } else if (checkedCount == 0) {
      $('#all_check').prop('checked', false).prop('indeterminate', false);
    } else {
      $('#all_check').prop('indeterminate', true);
    }
  })
});

function bulkActionDisplayToggle() {
  var checkedCount = $('.bulk-action-checkbox:checked').length;

  if(checkedCount > 0) {
    $('.bulk-action-btn').removeClass('hide');
  } else {
    $('.bulk-action-btn').addClass('hide');
  }
}
