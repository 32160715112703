import { getCookie, setCookie } from "./utility";

$(document).on('turbolinks:load', function () {
  // auto select all input texts when in focus
  $(document).on('focus','input[type=text]',function(){ this.select(); });

  // add asterisk mark in required fields
  var html = "<span style='color:red; padding:4px;' title='This is required' data-bs-toggle='tooltip'>*</span>"
  // for normal inputs and stuff
  $("input[required], select[required], checkbox[required], radio[required]").parent().find('label').append(html);
  // for selectized elements
  $("input[required]").parents('.selectize-control').parent().find('label').append(html);

  $('.alert .close').click(function () {
    $(this).parent().slideUp();
  });

  $('.menu_link').click(function () {
    $('#hoverbar').fadeToggle();
  });

  $(window).resize(function () {
    if ($(window).width() > 991) {
      $('#hoverbar').show();
    } else {
      $('#hoverbar').hide();
    }
  });

  tippyTooltip();
});

$(document).on('click', '.remove_fields, .remove_attachment_fields', function (event) {
  event.preventDefault();
  const parentNodeName = $(this).parent().prop('nodeName')
  const closestParentNodeName = parentNodeName == "TD" ? "tr" : "section";
  $(this).prev('input[type=hidden]').val('1');
  $(this).closest(closestParentNodeName).find(':input').removeAttr('required');
  $(this).closest(closestParentNodeName).hide();
});

$(document).on('click', '.add_fields', function (event) {
  event.preventDefault();
  var regexp, time;
  time = new Date().getTime();
  regexp = new RegExp($(this).data('id'), 'g');
  var formData = $(this).data('fields').replace(regexp, time);
  formData = formData.replace(new RegExp('add-item-row-selectize', 'g'), `add-item-row-selectize-${time}`)
  formData = formData.replace(new RegExp('si-selectize', 'g'), `si-selectize-${time}`)
  formData = formData.replace(new RegExp('mu-selectize', 'g'), `mu-selectize-${time}`)
  $(this).parent().before(formData);
  tippyTooltip();

  $(`.add-item-row-selectize-${time}`).selectize();
  $(`.si-selectize-${time}`).selectize();
  $(`.mu-selectize-${time}`).selectize({
    plugins: ['remove_button']
  });

  $(`.wselectize-${time}`).selectize({
    create: function(input) {
      return {
        value: input,
        text: input
      }
    },
    delimiter: ',',
    persist: false
  });
  $(`.uom-selectize-${time}`).selectize({
    create: function(input) {
      return {
        value: input,
        text: input
      }
    },
    delimiter: ',',
    persist: false,
    plugins: ['inputMaxlength'],
    inputMaxlength: 4
  });
});

function tippyTooltip() {
  if (!window.hasOwnProperty("tippy") ) return;

  tippy('.tippy-tooltip', {
    content(reference) {
      const id = reference.getAttribute('data-template');
      const template = document.getElementById(id);
      return template.innerHTML;
    },
    allowHTML: true,
    trigger: 'mouseenter focus',
    interactive: true,
    theme: 'custom',
    animation: 'shift-toward-subtle',
    placement: 'right',
    arrow: false
  });

  tippy('.indicator', {
    content(reference) {
      const id = reference.getAttribute('data-template');
      const template = document.getElementById(id);
      return template?.innerHTML;
    },
    allowHTML: true,
    trigger: 'mouseenter focus',
    interactive: true,
    animation: 'shift-toward-subtle',
    placement: 'top'
  });
}

$(document).on('change', '#required_company_id', function (e) {
  e.preventDefault();
  var companyId = $(this).find(':selected').val();

  var optionsCom = '<option value>Loading...</option>';
  var req = $('#required_office_dropdown').attr('required');

  $('#required_office_dropdown').selectize()[0].selectize.destroy();
  $('#required_office_dropdown').html(optionsCom);
  $('#required_office_dropdown').selectize();

  $.ajax({
    method: 'GET',
    url: `/companies/${companyId}/offices`,
    dataType: 'json',
    success: function (data, status, xhr) {
      optionsCom = '<option value>Select Office</option>';

      $.each(data['offices'], function (index, office) {
        optionsCom += `<option value='${office.id}'>${office.name}</option>`;
      });

      $('#required_office_dropdown').prop('required', req);
      $('#required_office_dropdown').selectize()[0].selectize.destroy();
      $('#required_office_dropdown').html(optionsCom);
      $('#required_office_dropdown').selectize();
    }
  });
});

$(document).on('change', '.item-selection-dropdown', function (e) {
  e.preventDefault();

  var itemId = $(this).find(':selected').val();
  if (!itemId) return;

  let select_catalogue = `#required_item_catalogue_dropdown`;
  let select_unit = `#required_uom_dropdown`;
  let select_currency = `#currency_dropdown`;
  
  var optionsBorC = '<option value>Loading...</option>';
  $(`${select_catalogue}`).selectize()[0].selectize.destroy();
  $(`${select_catalogue}`).html(optionsBorC);
  $(`${select_catalogue}`).selectize();

  var optionsUOM = '<option value>Loading...</option>';
  $(`${select_unit}`).selectize()[0].selectize.destroy();
  $(`${select_unit}`).html(optionsUOM);
  $(`${select_unit}`).selectize();

  $.ajax({
    method: 'GET',
    url: `/items/${itemId}`,
    dataType: 'json',
    success: function (data, status, xhr) {
      // handle rate
      let { buying_price, buying_currency, uom_id } = data.item;

      var control = $(`${select_currency}`).selectize()
      if (control && control.length > 0) {
        control[0].selectize.setValue(buying_currency)
      }
      $("input[name$='[rate]']").val(buying_price)

      // handle item catalogues
      optionsBorC = '<option value>Brand or Catalogue</option>';
      $.each(data.item['item_catalogues'], function (index, item_catalogue) {
        optionsBorC += `<option value='${item_catalogue.id}'>${item_catalogue.brand_with_cat_no}</option>`;
      });

      $(`${select_catalogue}`).selectize()[0].selectize.destroy();
      $(`${select_catalogue}`).html(optionsBorC);
      $(`${select_catalogue}`).selectize();

      // handle unit
      optionsUOM = '<option value>Unit</option>';

      $.each(data.item['uoms'], function (index, uom) {
        optionsUOM += `<option value='${uom.id}'>${uom.short_name}</option>`;
      });

      $(`${select_unit}`).selectize()[0].selectize.destroy();
      $(`${select_unit}`).html(optionsUOM);
      $(`${select_unit}`).val(uom_id);
      $(`${select_unit}`).selectize();
    },
    error: function () {
      // handle item catalogues
      optionsBorC = '<option value>Failed!</option>';

      $(`${select_catalogue}`).selectize()[0].selectize.destroy();
      $(`${select_catalogue}`).html(optionsBorC);
      $(`${select_catalogue}`).selectize();

      // handle unit
      optionsUOM = '<option value>Failed!</option>';
      let unit = "";

      $(`${select_unit}`).selectize()[0].selectize.destroy();
      $(`${select_unit}`).html(optionsUOM);
      $(`${select_unit}`).val(unit);
      $(`${select_unit}`).selectize();
    }
  });
});

$(document).on('click', '.extend-wrapper', function() {
  if (getCookie('extended')) {
    setCookie('extended', false);
    $('.extend-wrapper').removeClass('bg-info');
    $('.content-wrapper').removeClass('extended-view');
    $('.btn[data-bs-target*="modal"]').show();
  } else {
    setCookie('extended', true);
    $('.extend-wrapper').addClass('bg-info');
    $('.content-wrapper').addClass('extended-view');
    $('.btn[data-bs-target*="modal"]').hide();
  }
});

$(document).on('turbolinks:load', function() {
  if (getCookie('extended')) {
    $('.extend-wrapper').addClass('bg-info');
    $('.content-wrapper').addClass('extended-view');
    $('.btn[data-bs-target*="modal"]').hide();
  } else {
    $('.extend-wrapper').removeClass('bg-info');
    $('.content-wrapper').removeClass('extended-view');
    $('.btn[data-bs-target*="modal"]').show();
  }
});
