$(document).on('change', '#approval_level_office_id, #approval_level_project_id', function (e) {
  var officeId = $('#approval_level_office_id').find(':selected').val();
  var projectId = $('#approval_level_project_id').find(':selected').val();

  if (officeId || projectId) {
    var url = officeId ? `/offices/${officeId}/users` : `/projects/${projectId}/users`

    var options = '<option value>Loading...</option>';
    $('#approval_level_user_ids').selectize()[0].selectize.destroy();
    $('#approval_level_user_ids').html(options);
    $('#approval_level_user_ids').selectize();

    $.ajax({
      method: 'GET',
      url: url,
      dataType: 'json',
      success: function (data, status, xhr) {
        options = '<option value>Select Users</option>';

        $.each(data['users'], function (index, user) {
          options += `<option value='${user.id}'>${user.fullname}</option>`;
        });

        $('#approval_level_user_ids').selectize()[0].selectize.destroy();
        $('#approval_level_user_ids').html(options);
        $('#approval_level_user_ids').selectize();
      }
    });
  }
});
