$(document).on('turbolinks:load', function () {
  function tippyTooltip(klass) {
    if (!window.hasOwnProperty("tippy") ) return;

    tippy(klass, {
      content(reference) {
        const id = reference.getAttribute('data-template');
        const template = document.getElementById(id);
        return template.innerHTML;
      },
      allowHTML: true,
      trigger: 'mouseenter focus',
      interactive: true,
      theme: 'custom',
      animation: 'shift-toward-subtle',
      placement: 'right',
      arrow: false
    });
  }

  tippyTooltip('.tippy-tooltip');
});
