$(document).on('change', '.purchase-request-item-selection', function (e) {
  e.preventDefault();

  var itemId = $(this).find(':selected').val();
  var uniqueId = $(this).attr('id')?.split('_')[6];

  if (!itemId) return;

  const select_class = `#purchase_request_purchase_request_items_attributes_${uniqueId}`;
  let select_catalogue = `${select_class}_item_catalogue_id`;
  let select_unit = `${select_class}_uom_id`;
  let select_vendor = `${select_class}_preferred_vendor_id`;
  let decimal_rate = `${select_class}_estimated_rate`;

  // var selectizeParams1 = {
  //   create: function(input) {
  //     return {
  //       value: input,
  //       text: input
  //     }
  //   },
  //   delimiter: ',',
  //   persist: false
  // }

  var optionsBorC = '<option value>Loading...</option>';
  $(`${select_catalogue}`).selectize()[0].selectize.destroy();
  $(`${select_catalogue}`).html(optionsBorC);
  $(`${select_catalogue}`).selectize();

  // var selectizeParams2 = {
  //   create: function(input) {
  //     return {
  //       value: input,
  //       text: input
  //     }
  //   },
  //   delimiter: ',',
  //   persist: false,
  //   plugins: ['inputMaxlength'],
  //   inputMaxlength: 4
  // }

  var optionsUOM = '<option value>Loading...</option>';
  $(`${select_unit}`).selectize()[0].selectize.destroy();
  $(`${select_unit}`).html(optionsUOM);
  $(`${select_unit}`).selectize();

  $.ajax({
    method: 'GET',
    url: `/items/${itemId}`,
    dataType: 'json',
    success: function (data, status, xhr) {
      // handle rate
      let { buying_price, buying_currency, uom_id, preferred_vendor_id } = data.item;

      // handle item catalogues
      optionsBorC = '<option value>Item Catalogue</option>';
      $.each(data.item['item_catalogues'], function (index, item_catalogue) {
        optionsBorC += `<option value='${item_catalogue.id}'>${item_catalogue.brand_with_cat_no}</option>`;
      });

      $(`${select_catalogue}`).selectize()[0].selectize.destroy();
      $(`${select_catalogue}`).html(optionsBorC);
      $(`${select_catalogue}`).selectize();

      // handle unit
      optionsUOM = '<option value>Unit</option>';

      $.each(data.item['uoms'], function (index, uom) {
        optionsUOM += `<option value='${uom.id}'>${uom.short_name}</option>`;
      });

      $(`${select_unit}`).selectize()[0].selectize.destroy();
      $(`${select_unit}`).html(optionsUOM);
      $(`${select_unit}`).val(uom_id);
      $(`${select_unit}`).selectize();

      // handle vendor
      $(`${select_vendor}`).selectize()[0].selectize.setValue(preferred_vendor_id);

      // handle rate
      const currency = $('#pr-currency').selectize()[0].selectize.getValue()

      if (currency == buying_currency) {
        $(`${decimal_rate}`).val(buying_price)
        $(`${decimal_rate}`).parent().find('#other-currency').text('')
      } else if (buying_price) {
        $(`${decimal_rate}`).val('')
        $(`${decimal_rate}`).parent().find('#other-currency').text(`${buying_currency} ${buying_price}`)
      } else {
        $(`${decimal_rate}`).val('')
        $(`${decimal_rate}`).parent().find('#other-currency').text('')
      }
    },
    error: function () {
      // handle item catalogues
      optionsBorC = '<option value>Failed!</option>';

      $(`${select_catalogue}`).selectize()[0].selectize.destroy();
      $(`${select_catalogue}`).html(optionsBorC);
      $(`${select_catalogue}`).selectize();

      // handle unit
      optionsUOM = '<option value>Failed!</option>';
      let unit = "";

      $(`${select_unit}`).selectize()[0].selectize.destroy();
      $(`${select_unit}`).html(optionsUOM);
      $(`${select_unit}`).val(unit);
      $(`${select_unit}`).selectize();
    }
  });
});
