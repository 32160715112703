var Loading = (() => {
  var ledger = {};
  const enable = (key) => {
    ledger[key] = true;
    // console.log(key, "enabling...", ledger)
  }
  const disable = (key) => {
    ledger[key] = false;
    // console.log(key, "disabling...", ledger)
  }
  const isAllDisabled = () => {
    return Object.values(ledger).every(e => !e)
  }
  return { enable, disable, isAllDisabled };
})();

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
  	var context = this, args = arguments;
  	clearTimeout(timeout);
  	if (immediate && !timeout) func.apply(context, args);
  	timeout = setTimeout(function() {
  		timeout = null;
  		if (!immediate) func.apply(context, args);
  	}, wait);
  };
}

function throttle(func, timeFrame) {
  var lastTime = 0;
  return function (...args) {
      var now = new Date();
      if (now - lastTime >= timeFrame) {
        func(...args);
        lastTime = now;
      }
  };
}

function hsv_to_rgb(h, s, v) {
  var h_i = parseInt(h*6)
  var f = h*6 - h_i
  var p = v * (1 - s)
  var q = v * (1 - f*s)
  var t = v * (1 - (1 - f) * s)

  if (h_i==0)      var [r, g, b] = [v, t, p]
  else if (h_i==1) var [r, g, b] = [q, v, p]
  else if (h_i==2) var [r, g, b] = [p, v, t]
  else if (h_i==3) var [r, g, b] = [p, q, v]
  else if (h_i==4) var [r, g, b] = [t, p, v]
  else if (h_i==5) var [r, g, b] = [v, p, q]

  var c = `rgb(${parseInt(r*256)}, ${parseInt(g*256)}, ${parseInt(b*256)})`
  return c;
}

function selectColor() {
  var h = Math.random()
  var golden_ratio_conjugate = 0.618033988749895 // use golden ratio

  return () => {
    h += golden_ratio_conjugate
    h %= 1
    return hsv_to_rgb(h, 0.5, 0.75)
  }
}
var randColor = selectColor()

function setCookie(name,value,days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function eraseCookie(name) {   
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export {Loading, debounce, throttle, randColor, setCookie, getCookie, eraseCookie}
